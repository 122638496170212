import { DatePicker } from '@hexa-ui/components';
import React, { useCallback } from 'react';
import { FormatDateOptions, useIntl } from 'react-intl';
import InputViewMode from '../../../InputViewMode/InputViewMode';
import { GenericInputProps } from '../../GenericAttributes.types';
import { handleGetLocale } from '../../utils/utils';

const DatePickerInput = ({
  onChange,
  attribute,
  name,
  errorMessage,
  hasError,
  isEditing,
  value,
}: GenericInputProps): React.ReactElement => {
  const { formatDate, locale, formatMessage } = useIntl();

  const handleFormatDate = useCallback(
    (value: string) => {
      if (value === '-') return value;
      const dateOptions: FormatDateOptions = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      };

      return formatDate(new Date(value), dateOptions);
    },
    [formatDate]
  );

  return (
    <>
      {isEditing ? (
        <DatePicker
          customLocale={handleGetLocale(locale)}
          onChange={(value) => onChange(value?.[0])}
          value={value ? [new Date(value as string)] : []}
          label={attribute.name}
          id={attribute.attributeId}
          required={attribute.isRequired}
          errorText={errorMessage}
          hasError={hasError}
          name={name}
          width={'100%'}
          mode="single"
          optionalText={formatMessage({ id: 'Components.OPTIONAL' })}
        />
      ) : (
        <InputViewMode
          id={attribute.attributeId}
          label={attribute.name}
          tooltipText={attribute.description}
          value={handleFormatDate(value as string)}
        />
      )}
    </>
  );
};

export default DatePickerInput;
