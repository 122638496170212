import * as yup from 'yup';
import { BooleanInput } from './components/BooleanInput/BooleanInput';
import DatePickerInput from './components/DatePicker/DatePicker';
import { GroupInput } from './components/Group/GroupInput';
import { ListInput } from './components/ListInput/ListInput';
import { MeasureInput } from './components/MeasureInput/MeasureInput';
import { TextInput } from './components/TextInput/TextInput';
import { TFieldsMetadata } from './GenericAttributes.types';
import {
  handleFormatBooleanBeforeChange,
  handleFormatBooleanBeforeRender,
  handleFormatDateBeforeChange,
  handleFormatIntBeforeChange,
  handleFormatListBeforeChange,
  handleFormatListBeforeRender,
  handleFormatMeasureBeforeChange,
  handleFormatMeasureBeforeRender,
  handleFormatNumberBeforeChange,
} from './utils/utils';

export const VALUE_PATH = 'value';
export const UOM_PATH = 'uom';
export const GROUPED_ATTRIBUTES_PATH = 'groupedAttributes';
export const ATTRIBUTE_PATH = 'attributes';

export const FieldsMetaData: TFieldsMetadata = {
  DATE: {
    schema: yup.string().datetime(),
    Component: DatePickerInput,
    formatValueBeforeChange: handleFormatDateBeforeChange,
  },
  STRING: {
    schema: yup.string(),
    Component: TextInput,
  },
  NUMBER: {
    schema: yup.number(),
    Component: TextInput,
    formatValueBeforeChange: handleFormatNumberBeforeChange,
    type: 'number',
  },
  INT: {
    schema: yup.number().integer(),
    Component: TextInput,
    formatValueBeforeChange: handleFormatIntBeforeChange,
    type: 'number',
  },
  BOOLEAN: {
    schema: yup.boolean(),
    Component: BooleanInput,
    formatValueBeforeChange: handleFormatBooleanBeforeChange,
    formatValueBeforeRender: handleFormatBooleanBeforeRender,
  },
  LIST: {
    schema: yup.string(),
    Component: ListInput,
    formatValueBeforeChange: handleFormatListBeforeChange,
    formatValueBeforeRender: handleFormatListBeforeRender,
  },
  MEASURE: {
    schema: yup.number(),
    type: 'number',
    Component: MeasureInput,
    formatValueBeforeRender: handleFormatMeasureBeforeRender,
    formatValueBeforeChange: handleFormatMeasureBeforeChange,
  },
  GROUP: {
    schema: yup.mixed(),
    Component: GroupInput,
  },
};
