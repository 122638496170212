const permissionConstants = {
  PRODUCTS_CREATE: 'CatalogAdmin.Products.Create',
  PRODUCTS_DELETE: 'CatalogAdmin.Products.Delete',
  PRODUCTS_READ: 'CatalogAdmin.Products.Read',
  PRODUCTS_UPDATE: 'CatalogAdmin.Products.Update',
  CATALOG_COUNTRIES_ALL_SCOPE: 'CatalogAdmin.CountriesAll.Execute',
  CATALOG_VENDORS_ALL_SCOPE: 'CatalogAdmin.VendorsAll.Execute',
  ITEM_WRITE: 'CatalogAdmin.Item.Write',
  ITEM_READ: 'CatalogAdmin.Item.Read',
  CATEGORY_READ: 'CatalogAdmin.Categories.Read',
  CATEGORY_WRITE: 'CatalogAdmin.Categories.Write',
  PRODUCT_WRITE: 'CatalogAdmin.Product.Write',
} as const;

export default permissionConstants;
