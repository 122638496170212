import { Heading } from '@components';
import { GROUPED_ATTRIBUTES_PATH } from '../../constants';
import { GenericAttributesFields, GroupWrapper } from '../../GenericAttributes.styles';
import { GenericInputProps, NamePath } from '../../GenericAttributes.types';
import { ControllerWrapper } from '../ControllerWrapper/ControllerWrapper';

const GroupInput = ({
  form,
  attribute,
  isEditing,
  baseName,
}: GenericInputProps): React.ReactElement => {
  return (
    <GroupWrapper>
      <Heading title={attribute.name} mb="4" size="H5" />
      <GenericAttributesFields variant={isEditing ? undefined : 'grouped'}>
        {attribute[GROUPED_ATTRIBUTES_PATH]?.map((groupedAttribute, index) => (
          <ControllerWrapper
            attribute={groupedAttribute}
            form={form}
            isEditing={isEditing}
            name={`${baseName}.${GROUPED_ATTRIBUTES_PATH}.${index}` as NamePath}
            key={groupedAttribute.attributeId}
          />
        ))}
      </GenericAttributesFields>
    </GroupWrapper>
  );
};

export { GroupInput };
