import { GenericInputProps } from '@components/GenericAttributes/GenericAttributes.types';
import { Select } from '@components/Select/Select';
import { useIntl } from 'react-intl';
import { isEmpty } from '../../utils/utils';

const BooleanInput = ({
  name,
  attribute,
  isEditing,
  errorMessage,
  onChange,
  value,
}: GenericInputProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  const options = [
    {
      value: 'true',
      label: formatMessage({ id: 'Components.YES' }),
    },
    {
      value: 'false',
      label: formatMessage({ id: 'Components.NO' }),
    },
  ];

  const getValue = () => {
    if (isEmpty(value)) return String(value);
    const selectedOption = options.find(({ value: option }) => option === value);
    return isEditing ? selectedOption?.value : selectedOption?.label;
  };

  return (
    <Select
      selectProps={{
        label: attribute.name,
        name: name,
        error: errorMessage,
        value: getValue(),
        onChange,
        multiple: false,
        placeholder: formatMessage({ id: 'Components.SELECT_AN_OPTION' }),
        width: '100%',
      }}
      id={attribute.attributeId}
      options={options}
      required={attribute.isRequired}
      viewMode={!isEditing}
      tooltipText={attribute.description}
    />
  );
};

export { BooleanInput };
