import { Button, Chip, Paragraph, SearchField, TextButton } from '@hexa-ui/components';
import { ChevronDown, ChevronUp, Filter as FilterIcon, X } from '@hexa-ui/icons';
import { useStore, useStoreMap } from 'effector-react';
import { type FormEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { resetFilter, setFilter } from 'stores/itemsList/ItemsListEvents';
import { ItemsFilterProps, SearchByEnum } from 'stores/itemsList/ItemsListState';
import itemsFilterStore from 'stores/itemsList/ItemsListStore';
import sellersStore from 'stores/sellers/SellersStore';
import { StatusEnum } from 'types/status/status.enum';
import Popover from './components/Popover/Popover';
import {
  ChipsContainer,
  FilterActionsContainer,
  FilterActionsInputContainer,
  FilterActionsLabel,
  FilterContainer,
  SearchInputContainer,
} from './FilterTable.styles';
import { createSearchByOptions } from './utils/createSearchByOptions/createSearchByOptions';
import { getSearchByTranslationKey } from './utils/getSearchByTranslationKey/getSearchByTranslationKey';

export enum ItemsFilterTabs {
  STATUS = 'Status',
  SELLER = 'Seller',
}

interface FilterProps {
  onApply?: (filters: Partial<ItemsFilterProps>) => void;
  onClear?: () => void;
  onCancel?: () => void;
  enabledTabs?: ItemsFilterTabs[];
  showSearchFieldFilter?: boolean;
  pageState?: {
    page: number;
    setPage: (page: number) => void;
  };
  filterSearchTriggeredState?: {
    isFilterSearchTriggered: boolean;
    setIsFilterSearchTriggered: (isFilterSearchTriggered: boolean) => void;
  };
}

const BASE_SEARCH_PLACEHOLDER_KEY = 'Filter.SEARCH_PLACEHOLDER.';

const FilterTable = ({
  onApply,
  onClear,
  onCancel,
  pageState,
  filterSearchTriggeredState,
  enabledTabs = [ItemsFilterTabs.STATUS, ItemsFilterTabs.SELLER],
  showSearchFieldFilter = true,
}: FilterProps): React.ReactElement => {
  const { filter } = useStoreMap({
    store: itemsFilterStore,
    keys: [],
    fn: (state) => ({
      filter: state.filter,
    }),
  });
  const { defaultSeller } = useStore(sellersStore);
  const { formatMessage } = useIntl();
  const [show, setShow] = useState(false);
  const [initialTab, setInitialTab] = useState<ItemsFilterTabs>(ItemsFilterTabs.STATUS);

  const [searchBy, setSearchBy] = useState(filter.searchBy);
  const [inputText, setInputText] = useState(filter.inputText);

  const shouldShowClearAll = filter.status;

  const searchByOptions = createSearchByOptions({ formatMessage, getSearchByTranslationKey });

  function handleApply({ status, sellers }) {
    onApply({ status, sellers });
    setShow(false);
  }

  function handleCancel() {
    onCancel?.();
    setShow(false);
  }

  const handleChangeSearch = (event) => {
    setInputText(event.target.value);
  };

  const resetPage = () => {
    if (pageState && pageState.page !== 1) pageState.setPage(1);
  };

  const handleSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    resetPage();
    setFilter({ ...filter, searchBy, inputText });

    filterSearchTriggeredState?.setIsFilterSearchTriggered(true);
  };

  const handleInputFocusAfterSearch = (input: HTMLInputElement | undefined) => {
    if (filterSearchTriggeredState?.isFilterSearchTriggered && input) input.focus();
  };

  const handleClearSearch = () => {
    setInputText('');

    if (filter.inputText) setFilter({ ...filter, inputText: '' });
  };

  const handleOpenPopoverByChip = (value) => {
    setInitialTab(value);
    setShow(true);
  };

  const handleSearchByChange = (value: SearchByEnum) => {
    resetPage();
    setSearchBy(value);
    setInputText('');

    if (filter.inputText) setFilter({ ...filter, searchBy: value, inputText: '' });
  };

  return (
    <FilterContainer>
      <FilterActionsContainer>
        <Button
          css={{ border: !show && '1px solid #00000029', width: '152px' }}
          elevated={false}
          id="filter-button"
          size="medium"
          variant="secondary"
          onClick={() => setShow((prev) => !prev)}
        >
          <FilterActionsLabel>
            <FilterIcon size="medium" />
            <span>{formatMessage({ id: 'Filter.FILTER' })}</span>
          </FilterActionsLabel>
          {!show ? <ChevronDown size="medium" /> : <ChevronUp size="medium" />}
        </Button>
        <FilterActionsInputContainer>
          <SearchInputContainer onSubmit={handleSubmitSearch}>
            <SearchField.Root
              ref={handleInputFocusAfterSearch}
              size="medium"
              value={inputText}
              onClear={handleClearSearch}
              onChange={handleChangeSearch}
              placeholder={formatMessage({
                id: BASE_SEARCH_PLACEHOLDER_KEY.concat(getSearchByTranslationKey(searchBy)),
              })}
            >
              {showSearchFieldFilter ? (
                <SearchField.Filter
                  size="large"
                  value={searchBy}
                  onChange={handleSearchByChange}
                  data-testid="search-by-filter"
                >
                  {searchByOptions.map(({ label, value }) => (
                    <SearchField.FilterOption key={value} value={value}>
                      {label}
                    </SearchField.FilterOption>
                  ))}
                </SearchField.Filter>
              ) : undefined}
            </SearchField.Root>
          </SearchInputContainer>
        </FilterActionsInputContainer>
      </FilterActionsContainer>
      {show && (
        <Popover
          filter={filter}
          initialTab={initialTab}
          enabledTabs={enabledTabs}
          onApply={handleApply}
          onClear={onClear}
          onCancel={handleCancel}
        />
      )}
      {(filter.status || filter.sellers.length > 0) && (
        <ChipsContainer>
          <Paragraph>{formatMessage({ id: 'Filter.FILTERED_BY' })}</Paragraph>
          {filter.status && (
            <Chip.Root onClick={() => handleOpenPopoverByChip(ItemsFilterTabs.STATUS)}>
              <Chip.Label css={{ 'max-width': '150px' }}>
                {`${formatMessage({ id: 'Filter.STATUS' })} ${
                  filter.status === StatusEnum.ENABLED
                    ? formatMessage({ id: 'Filter.ENABLED' })
                    : formatMessage({ id: 'Filter.DISABLED' })
                }`}
              </Chip.Label>
              <Chip.DeleteIcon onDelete={() => setFilter({ ...filter, status: '' })} />
            </Chip.Root>
          )}
          {filter.sellers.length === 1 && (
            <Chip.Root
              selected
              style={{ backgroundColor: 'rgb(233,241,255)' }}
              onClick={() => handleOpenPopoverByChip(ItemsFilterTabs.SELLER)}
            >
              <Chip.Label css={{ 'max-width': '480px' }}>
                {`${formatMessage({ id: 'Filter.SELLER' })} ${filter.sellers
                  .map((seller) => seller.name)
                  .join(', ')}`}
              </Chip.Label>
            </Chip.Root>
          )}
          {filter.sellers.length > 1 && (
            <Chip.Root onClick={() => handleOpenPopoverByChip(ItemsFilterTabs.SELLER)}>
              <Chip.Label css={{ 'max-width': '480px' }}>
                {`${formatMessage({ id: 'Filter.SELLER' })} ${filter.sellers
                  .map((seller) => seller.name)
                  .join(', ')}`}
              </Chip.Label>
              <Chip.DeleteIcon
                onDelete={() => setFilter({ ...filter, sellers: [defaultSeller] })}
              />
            </Chip.Root>
          )}
          {shouldShowClearAll && (
            <TextButton
              icon={X}
              onClick={() => resetFilter({ defaultSeller })}
              iconPosition="leading"
            >
              {formatMessage({ id: 'Filter.CLEAR_ALL' })}
            </TextButton>
          )}
        </ChipsContainer>
      )}
    </FilterContainer>
  );
};

export { FilterTable };
