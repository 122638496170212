import { Skeleton } from '@components';
import {
  FileUploader as FileUploaderComponent,
  HandleFileUpload,
  Zones,
} from '@content-shared-components/file-uploader';
import { forwardRef } from 'react';

export interface FileUploaderProps {
  initialImageUrl?: string;
  isEditable: boolean;
  isDisabled: boolean;
  isUpdating?: boolean;
  country: string;
  onDropAccepted: () => void;
  onClear: () => void;
  externalError: string | boolean;
  style?: React.CSSProperties;
  maxFileSize?: number;
}

export const FileUploader = forwardRef<HandleFileUpload, FileUploaderProps>(function FileUploader(
  {
    country,
    externalError,
    initialImageUrl,
    isDisabled,
    isEditable,
    onClear,
    onDropAccepted,
    isUpdating,
    style,
    maxFileSize = 2 * 1024 * 1024,
  },
  ref
) {
  const defaultStyle = { height: 376, width: '100%', maxWidth: '100%' };
  return (
    <Skeleton
      height="376px"
      width="100%"
      id="file-uploader-skeleton"
      imageShape="rectangle"
      variant="image"
    >
      <FileUploaderComponent
        ref={ref}
        onClear={onClear}
        maxFileSize={maxFileSize}
        style={style || defaultStyle}
        zone={country.toLowerCase() as Zones}
        objectFit="contain"
        error={externalError}
        onDropAccepted={onDropAccepted}
        isEditable={isEditable}
        disabled={isDisabled || isUpdating}
        initialImageUrl={initialImageUrl}
      />
    </Skeleton>
  );
});
