import { Input } from '@components';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Select } from '../../../Select/Select';
import { UOM_PATH } from '../../constants';
import { MeasureWrapper } from '../../GenericAttributes.styles';
import { GenericInputProps, NamePath } from '../../GenericAttributes.types';

const MeasureInput = ({
  form,
  name,
  attribute,
  isEditing,
  errorMessage,
  hasError,
  onChange,
  value,
  type,
  baseName,
}: GenericInputProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    return onChange(e.currentTarget.value);
  };

  return (
    <MeasureWrapper>
      <Input
        onChange={handleChange}
        value={String(value)}
        label={attribute.name}
        id={attribute.attributeId}
        required={attribute.isRequired}
        viewMode={!isEditing}
        errorText={errorMessage}
        hasError={hasError}
        width={'100%'}
        placeholder=""
        tooltipText={attribute.description}
        optionalText={formatMessage({ id: 'Components.OPTIONAL' })}
        name={name}
        type={type}
        min={0}
      />

      <Controller
        {...form.register(`${baseName}.${UOM_PATH}` as NamePath)}
        control={form.control}
        render={({ field }) => {
          return (
            <Select
              selectProps={{
                ...field,
                width: '100%',
                placeholder: formatMessage({ id: 'Components.SELECT_AN_OPTION' }),
                value: String(field.value),
                multiple: false,
              }}
              id={attribute.attributeId}
              options={attribute.units?.map((option) => ({ value: option, label: option })) ?? []}
              hide={!isEditing}
            />
          );
        }}
      />
    </MeasureWrapper>
  );
};

export { MeasureInput };
